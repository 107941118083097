import { PropTypes } from 'prop-types';
import React from 'react';
import { Resizable } from 'react-resizable';
import './ResizableTitle.less';

/**
 * 可伸缩列组件
 */
export default class ResizeableTitle extends React.Component {
  constructor() {
    super();
    this.state = {
      // 防止排序冲突
      allowClick: false
    };
  }

  render() {
    const { onResize, onClick, width, ...restProps } = this.props;

    if (!width) {
      return <th {...restProps} />;
    }

    return (
      <Resizable
        width={width}
        height={0}
        onResize={onResize}
        draggableOpts={{ enableUserSelectHack: false }}
        onResizeStart={() => {
          this.setState({ allowClick: false });
        }}
        handle={
          <span
            className="react-resizable-handle"
            onClick={e => {
              e.stopPropagation();
            }}
          />
        }
      >
        <th {...restProps} onMouseDown={e => {
          this.setState({ allowClick: !!onClick });
        }}
        onClick={e => { this.state.allowClick && onClick(e); }} />
      </Resizable>
    );
  }
};

ResizeableTitle.propTypes = {
  onResize: PropTypes.func,
  onClick: PropTypes.func,
  width: PropTypes.number
};
