import React from 'react';
import './Main.less';

/**
 * 主页面
 */
export default class Main extends React.Component {
  render() {
    return (
      <div className="main-page">
        Main
      </div>
    );
  }
}
