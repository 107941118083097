import { combineReducers } from 'redux';
import tab from './tab';

export const appReducer = combineReducers({
  tab
});

export const rootReducer = (state, action) => {
  // 注销操作时、清空state数据
  if (action.type === 'LOG_OUT') {
    state = undefined;
  }

  return appReducer(state, action);
};
