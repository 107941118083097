import React from 'react';
import { Table } from 'antd';
import { PropTypes } from 'prop-types';
import { isEqual, isEmpty } from 'lodash';

import Constants from 'constants/Constants';
import ResizeableTitle from './baseContents/ResizableTitle';
import YWTColumnSettingModal from 'components/ywtColumnSettingModal/YWTColumnSettingModal';
import './YWTSimpleTable.less';

export default class YWTSimpleTable extends React.Component {
  // 本地存储的列信息, 结构 { key: 列 key, { width: 列宽度 }}
  columnsInLocalStorage = {};
  // 保存标识，只有在被拖拽时保存
  needSave = false;

  rowSelectedEvent = null;

  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      selectedRows: [],
      columns: [],
      initSettingColumns: []
    };
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }

    if (!this.props.orginColums) {
      this.initColumns(this.props.columns);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
    if (this.needSave && this.props.tableKey) {
      localStorage.setItem(this.props.tableKey, JSON.stringify(this.columnsInLocalStorage));
    }
  }

  initColumns(columns) {
    columns = this.initEditColumns(columns);
    this.setState({
      columns
    });
  }

  // 将列的顺序按照，本地缓存的顺序进行渲染
  // 参数oldList: 传入需要进行排序的list
  sortList = (oldList) => {
    const key = this.props.tableKey + '_columnSetting';
    const columnsList = [];
    if (key && JSON.parse(localStorage.getItem(key))) {
      JSON.parse(localStorage.getItem(key)).forEach(item => {
        const data = oldList.find(rItem => (rItem.key || null) === item);
        if (data) {
          columnsList.push(data);
        }
      });
    } else {
      columnsList.push(...oldList);
    }
    return columnsList;
  }

  initEditColumns = (columns) => {
    const { isCustomizeColumnWidth } = this.props;

    // 获取 localStorage 中保存的列
    if (this.props.tableKey) {
      this.columnsInLocalStorage = JSON.parse(localStorage.getItem(this.props.tableKey)) || {};
    }

    if (columns.length === 0) {
      columns.push({ title: '　' });
      return columns;
    }

    if (columns.length === 0) {
      columns.push({ title: '　' });
      return columns;
    }

    // 获取最后列定义
    const lastColumn = columns[columns.length - 1];

    if (!isEmpty(lastColumn.title) && !isEqual(lastColumn.title, '　')) {
      // 根据最后列是否为操作列、在最后添加空列
      if (isEqual(lastColumn.title, '操作') || isEqual(lastColumn.fixed, 'right')) {
        columns.push({ title: '　', fixed: 'right' });
      } else {
        columns.push({ title: '　' });
      }
    }

    const maxIndex = columns.length - 1;

    columns = columns.map((col, index) => {
      const { width, ...restProps } = col;
      let colWidth = null;
      if (this.columnsInLocalStorage[col.key] == null) {
        if (isCustomizeColumnWidth) {
          // 自定义宽度
          colWidth = width;
        } else {
          // 未设置宽度的统一设置为 100、最后列不设置宽度
          if (index < maxIndex) {
            if (width) {
              colWidth = width;
            } else {
              colWidth = 100;
            }
          }
        }

        this.columnsInLocalStorage[col.key] = { width: colWidth };
      } else {
        colWidth = this.columnsInLocalStorage[col.key].width;
      }

      return ({
        ...restProps,
        width: colWidth,
        ellipsis: true,
        showSorterTooltip: false,
        onHeaderCell: column => ({
          width: column.width,
          onResize: this.handleResize(index)
        })
      });
    });

    return columns;
  }

  // 设置选择行
  setSelectRows = (rows = [], event) => {
    const selectedRowKeys = rows.map(value => value.id);
    this.setState({
      selectedRowKeys,
      selectedRows: rows || []
    });

    this.props.onSelectedRows && this.props.onSelectedRows(rows, event);
  }

  // 支持行选择
  onRowSelect = (selectRow, event) => {
    // CheckBox 的时候存在多个
    var rows = null;
    if (isEqual(this.props.rowSelectionType, 'checkbox')) {
      rows = this.state.selectedRows;
      var keyIndex = -1;
      for (var i = 0; i < this.state.selectedRows.length; i++) {
        if (this.state.selectedRows[i].id === selectRow.id) {
          keyIndex = i;
          break;
        }
      }
      if (keyIndex > -1) {
        rows.splice(keyIndex, 1);
      } else {
        rows.push(selectRow);
      }

      // Radio 的时候只有一个
    } else if (isEqual(this.props.rowSelectionType, 'notShow')) {
      rows = null;
      return;
    } else {
      rows = [selectRow];
    }
    this.setSelectRows(rows, event.nativeEvent);
  }

  // 单选事件（为了获取鼠标点击事件）
  onSelect = (record, selected, selectedRows, nativeEvent) => {
    this.rowSelectedEvent = nativeEvent;
  }

  // 单选事件
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setSelectRows(selectedRows, this.rowSelectedEvent);
  };

  // 全选事件
  onSelectAll = (_, selectedRows) => {
    this.setSelectRows(selectedRows, null);
  }

  // 页面排序处理
  handleTableChange = (pagination, filters, sorter) => {
    var sortString = '';
    // 只有当当前显示列包含排序列,并且排序方式存在时,才传递排序字段和排序方式
    if (this.state.columns && this.state.columns.includes(sorter.column)) {
      if (sorter.order) {
        sortString = sorter.columnKey + ' ' + Constants.sortMap[sorter.order];
      }
    }
    this.props.onPageChange(sortString, pagination.current, pagination.pageSize);
  }

  // 列拖拽时
  // 重新设置列宽
  handleResize = index => (e, { size }) => {
    if (size.width !== this.state.columns[index].width) {
      this.needSave = true;
      const nextColumns = [...this.state.columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width
      };

      this.columnsInLocalStorage[nextColumns[index].key] = { width: size.width };

      this.setState({
        columns: nextColumns
      });
    }
  };

  // 从localStorage恢复显示列初始化
  initColumnsFromSetting = (initSettingColumns) => {
    const colDefs = initSettingColumns && initSettingColumns.length > 0 ? initSettingColumns : this.props.columns;
    this.initColumns(colDefs);
  }

  // 打开列设置弹出框
  showColumnSettingModal = () => {
    this.columnSettingModalRef.editColumnSetting();
  }

  onRowSelect = (selectRow, event) => {
    // CheckBox 的时候存在多个
    var rows = null;
    if (this.props.rowSelectionType === 'checkbox') {
      rows = this.state.selectedRows;
      var keyIndex = -1;
      for (var i = 0; i < this.state.selectedRows.length; i++) {
        if (this.state.selectedRows[i].id === selectRow.id) {
          keyIndex = i;
          break;
        }
      }
      if (keyIndex > -1) {
        rows.splice(keyIndex, 1);
      } else {
        rows.push(selectRow);
      }

      // Radio 的时候只有一个
    } else if (this.props.rowSelectionType === 'notShow') {
      rows = null;
      return;
    } else {
      rows = [selectRow];
    }
    this.setSelectRows(rows, event.nativeEvent);
  }

  components = {
    header: {
      cell: ResizeableTitle
    }
  };

  render() {
    const {
      height, isLoading, rowSelectionType, pagination, pageSizeOptions, rowSelection, defaultPageSize,
      current, total, defaultCurrent, ...rest
    } = this.props;

    return (
      <React.Fragment>
        <Table
          {...rest}
          bordered
          tableLayout='fixed'
          size='small'
          onRow={(record) => ({
            // record ? null : this.onRowSelect(record, event)
            // onClick: (event) => (this.state.selectedRows ? null : this.onRowSelect(record, event))
            onClick: (e) => this.onRowSelect(record, e)
          })}
          scroll={{ x: '100%', y: height }}
          rowKey={(record) => record.id}
          components={this.components}
          columns={this.state.columns}
          onChange={this.handleTableChange}
          loading={isLoading}
          rowSelection={
            isEqual(rowSelectionType, 'notShow') ? null : {
              columnWidth: 50,
              type: (rowSelectionType == null || isEqual(rowSelectionType, 'radio')) ? 'radio' : 'checkbox',
              selectedRowKeys: this.state.selectedRowKeys,
              onSelect: this.onSelect,
              onChange: this.onSelectChange,
              onSelectAll: this.onSelectAll,
              getCheckboxProps: rowSelection && rowSelection.getCheckboxProps
            }
          }
          pagination={pagination ? {
            current: current,
            total: total,
            defaultCurrent: defaultCurrent || 1,
            defaultPageSize: defaultPageSize || 10,
            showTotal: (total, range) => `第${range[0]}到${range[1]}条，共${total}条`,
            showLessItems: true,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: pageSizeOptions || ['10', '20', '50', '100']
          } : false}>

        </Table>

        {/** 列设置弹出框 */}
        {
          this.props.orginColums
            ? <YWTColumnSettingModal onRef={ref => (this.columnSettingModalRef = ref)} orginColums={this.props.orginColums}
              tableKey={this.props.tableKey} onReload={this.props.onReload} onInit={this.initColumnsFromSetting} />
            : null
        }

      </React.Fragment>
    );
  }
}

// 属性值类型
YWTSimpleTable.propTypes = {
  // 每个表格唯一key 格式为[tb_ + 文件名]、同一文件多个表格后面加数字来区分
  // 例如：tb_profileManagement、tb_profileManagement_01、tb_profileManagement_02
  tableKey: PropTypes.string,
  // 表格列定义
  columns: PropTypes.array.isRequired,
  // 查询结果
  dataSource: PropTypes.array.isRequired,
  // 查询条件变化所用函数（条件变化包括：分页、排序）
  onPageChange: PropTypes.func,
  // true：分页/false：无分页
  pagination: PropTypes.bool,
  // true：分页/false：无分页
  pageSizeOptions: PropTypes.array,
  // pagination为true时、设置查询结果总数量
  total: PropTypes.number,
  // pagination为true时、设置查询展示页
  current: PropTypes.number,
  // 默认初始页数
  defaultCurrent: PropTypes.number,
  // 默认每页条数
  defaultPageSize: PropTypes.number,
  // 是否显示加载中
  isLoading: PropTypes.bool,
  // 行选择类型（可选值：checkbox、radio）
  rowSelectionType: PropTypes.string,
  // 选中行函数 Function(selectedRows) 当前选择的列
  onSelectedRows: PropTypes.func,
  // 获取Table ref
  onRef: PropTypes.func,
  // 是否自定义列宽
  isCustomizeColumnWidth: PropTypes.bool,
  // y轴高度
  height: PropTypes.node,
  // 行样式设置
  rowClassName: PropTypes.func,
  // 标题
  title: PropTypes.func,
  orginColums: PropTypes.array,
  onReload: PropTypes.func,
  rowSelection: PropTypes.object
};
