import {
  Button, Dropdown, Menu} from 'antd';
import {
  DownOutlined, LogoutOutlined, UserOutlined, SwapOutlined,
  LockOutlined, NotificationOutlined
} from '@ant-design/icons';
import BaseComponent from 'base/BaseComponent';
import { enhance } from 'base/BaseEnhance';
import React from 'react';
import SessionService from 'service/SessionService';
import NoticeModal from './noticeModal/NoticeModal';
import ChangeUserModal from './changeUserModal/ChangeUserModal';
import ChangePasswordModal from './changePasswordModal/ChangePasswordModal';
import Constants from 'constants/Constants';

/**
 * 主页头部用户信息
 */
class UserCenter extends BaseComponent {
  
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.checkShowChangePasswordModal();
  }

  showInitModal = () => {
    super.showMessage('密码过期，请修改');
    this.showChangePasswrodModal();
  }

  checkShowChangePasswordModal = () => {
    const param = this.props.location.state;
    if (param && param.resetPassword) {
      this.props.onCheck && this.props.onCheck('password',2);
    } else {
      this.props.onCheck && this.props.onCheck('password',1);
    }
  }

  userMenuClick = ({ key }) => {
    switch (key) {
      case 'changePassword':
        this.showChangePasswrodModal();
        break;
      case 'changeUser':
        this.showChangeUserModal();
        break;
      case 'showNoticeHistory':
        this.showNoticeHistoryModal();
        break;
      case 'logout':
        this.logout();
        break;
      default:
        break;
    }
  };

  userMenu = (
    <Menu onClick={this.userMenuClick}>
      {
        SessionService.systemType ===  Constants.SYSTEM_TYPE_SYS && 
          <Menu.Item key="changeUser"><SwapOutlined />切换用户</Menu.Item>
        
      }
      {
        SessionService.systemType !== Constants.SYSTEM_TYPE_SYS &&
          <Menu.Item key="showNoticeHistory"><NotificationOutlined />
            查看历史公告
          </Menu.Item>
      }
      <Menu.Item key="changePassword"><LockOutlined />修改密码</Menu.Item>
      <Menu.Item key="logout"><LogoutOutlined />注销</Menu.Item>
    </Menu>
  );

  // ----------- 公告相关处理 start ------------
    showNoticeHistoryModal = () => {
      this.noticeRef.showNoticeHistoryData();
    }

    showNoticeActiveModal = () => {
      console.log('showNoticeActiveModal');
      this.noticeRef.showNoticeActiveModal();
    }
  // ----------- 公告相关处理 end --------------

  // ----------- 修改密码相关处理 start ------------
  showChangePasswrodModal() {
    this.changePasswordRef && this.changePasswordRef.show();
  }
  // ----------- 修改密码相关处理 end --------------

  // ----------- 切换用户相关处理 start ------------
  showChangeUserModal = () => {
    this.changeUserRef && this.changeUserRef.show();
  }
  // ----------- 切换用户相关处理 end --------------


  logout() {
    console.log('logout',this);
    // 通知服务端系统登出
    super.sendPostRequest('/logout', {});
    // 清空sessionStorage数据
    SessionService.clear();

    // 跳转到登录页面
    super.jumpToLogin();
  }

  render() {
    return (
      <>
        <Dropdown overlay={this.userMenu}>
          <Button type="link" style={{ color: '#FFF' }}>
            <UserOutlined />
            {SessionService.userName}
            {SessionService.proxyUserName && ('(' + SessionService.proxyUserName + ')')}<DownOutlined />
          </Button>
        </Dropdown>
        {/* 切换用户弹出框 */}
        <ChangeUserModal onRef={ref => (this.changeUserRef = ref)}/>

        {/* 修改密码弹出框 */}
        <ChangePasswordModal onRef={ref => (this.changePasswordRef = ref)} onOk={() => this.logout()}/>

        {/* 历史公告弹出框 */}
        <NoticeModal onRef={ref => (this.noticeRef = ref)} 
         onCheck={(key,showFlag)=>this.props.onCheck && this.props.onCheck(key,showFlag)} 
         onCheckFinish={()=>this.props.onCheckFinish && this.props.onCheckFinish()} />
      </>
    );
  }
}

export default enhance(UserCenter);
