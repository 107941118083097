import { Col, Form, Input, Modal, Row, Select } from 'antd';
import BaseComponent from 'base/BaseComponent';
import { enhance } from 'base/BaseEnhance';
import { debounce } from 'lodash';
import React from 'react';
import SessionService from 'service/SessionService';

const { Option } = Select;


/**
 * 切换代理用户弹框
 */
class ChangeUserModal extends BaseComponent {
  userChangeFormRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      proxyUserList: []
    };

    this.getProxyUserList = debounce(this.getProxyUserList, 500);
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  show = () => {
    this.getProxyUserList();
    this.setState({
      visible: true
    });
  }

  hide = () => {
    this.setState({
      visible: false
    });
  }

  // 获取角色代理列表
  getProxyUserList = searchValue => {
    super.sendPostRequest('/system/proxy/getProxyUserList', { searchValueLike: searchValue },
      (result) => {
        this.setState({
          proxyUserList: result || []
        });
      });
  }

  changeProxyUser = value => {
    super.sendPostRequest('/changeProxyUser', { id: value.proxyUserId },
      (result) => {
        this.setState({
          visible: false
        });
        SessionService.userName = result.userName;
        SessionService.localUserName = result.userName;
        SessionService.proxyUserName = result.proxyUserName;
        SessionService.systemType = result.systemType;
        window.location.reload();
      });
  }

  render() {
    const {proxyUserList, visible } = this.state;

    return (
        <Modal width="500px" title="切换用户" visible={visible}
          onOk={()=> {this.userChangeFormRef.current.submit();}} onCancel={this.hide}
          okText="确认" cancelText="取消" className='addModal'>
             <Form ref={this.userChangeFormRef} onFinish={this.changeProxyUser}>
             <Row style={{ marginBottom: 5 }}>
            <Col span={6} style={{ textAlign: 'right', lineHeight: '30px' }}>
              当前代理用户：
            </Col>
            <Col span={18}>
              <Input disabled={true} value={SessionService.proxyUserName} />
            </Col>
          </Row>
          <Row gutter={[0, 16]}>
            <Col span={6} style={{ textAlign: 'right', lineHeight: '30px' }}>
              <span style={{color: 'red'}}>*</span>切换到：
            </Col>
            <Col span={18}>
            <Form.Item
              name="proxyUserId"
              rules={[{ required: true, message: '请选择代理用户' }]}
            >
              <Select showSearch style={{ width: '100%' }} placeholder="选择代理用户"
                allowClear={true}
                defaultActiveFirstOption={false} filterOption={false}
                notFoundContent={null}
                onSearch={this.getProxyUserList}>
                {
                  proxyUserList.map(userInfo =>
                    (<Option key={userInfo.userId}>{userInfo.userName}</Option>))
                }
              </Select>
              </Form.Item>
            </Col>
          </Row>
          </Form>
        </Modal>
    );
  }
}

export default enhance(ChangeUserModal);
