import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.less';
import Home from './view/home/Home';
import Login from './view/login/Login';
import PrivateRoute from 'PrivateRoute';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoggedIn: false, auth: false };
  }

  render() {
    return (
      <Router>
        <div className="main">
          <Switch>
            <Route path="/login">
              <Login/>
            </Route>
            <PrivateRoute path="/">
              <Home/>
            </PrivateRoute>
          </Switch>
        </div>
      </Router>
    );
  }
}
