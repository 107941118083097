import {
  Button, Layout} from 'antd';
import {
  QuestionCircleTwoTone} from '@ant-design/icons';
import BaseComponent from 'base/BaseComponent';
import { enhance } from 'base/BaseEnhance';
import React from 'react';
import UserCenter from './userCenter/UserCenter';
import Main from '../main/Main';
import './PageHeader.less';
import SessionService from 'service/SessionService';

const { Header } = Layout;

/**
 * 主页头部显示区域
 */
class PageHeader extends BaseComponent {
  
  constructor(props) {
    super(props);
    this.state = {};
    this.showListKey = ['password','notice'] // 检查顺序
    this.showList = [];
  }

  componentDidMount() {
    SessionService.nextShowOrder = 0;
    // 添加首页
   this.addTabMain();
  }

  componentWillUnmount() {
    clearInterval(this.todoFetchInterval);
  }

  addTabMain = () => {
    this.addTab('首页', Main, '/main', false);
  }

  //---检查弹框 start------------

  // 顺序显示弹出框(1:不需要弹出，2：需要弹出，其他：未加载完成)
  showModalByOrder = () => {
    // 上一检查完毕，获取下一检查(1:不需要弹出可跳过，2：需要弹出，其他：未加载完成)
    // 判断将要进行的步骤是否需要校验
    if(this.showList[SessionService.nextShowOrder] === 1){
      SessionService.nextShowOrder = SessionService.nextShowOrder+1;
      this.showModalByOrder();
    }else if(this.showList[SessionService.nextShowOrder] === 2){
      this.showModal(this.showListKey[SessionService.nextShowOrder]);
    }
  }

  // 设置检查项检查状态
  setShowList =(key,showFlag)=>{
    const index = this.showListKey.indexOf(key);
    if(index >= 0){
      this.showList[index] = showFlag;
      // 顺序执行
      SessionService.nextShowOrder <= index && this.showModalByOrder();
    }
  }

  // 检查完毕返回
  onCheckFinish = ()=>{
    SessionService.nextShowOrder = SessionService.nextShowOrder +1;
    this.showModalByOrder();
  }

  showModal = (key) => {
    switch (key) {
      case 'password':
        this.userCenterRef.showInitModal(); // 检查是否显示修改密码弹出框
        break;
      case 'notice':
        this.userCenterRef.showNoticeActiveModal(); // 获取公告信息
        break;
      default:
        break;
    }
  };
//---检查弹框 end------------
   
  render() {
    return (
      <Header className="ywt-dashboard-header" style={{ position: 'fixed', top: '0px', width: '100%' }}>
        <div className="ywt-dashboard-header-item">
          <div className="ywt-dashboard-header-left">全程可追溯智慧医疗供应链</div>
          <div className={this.props.isCollapsed ? 'ywt-dashboard-header-right' : 'ywt-dashboard-header-right-open'}>
            {/* 用户个人信息展示 */}
            <UserCenter onRef={ref => (this.userCenterRef = ref)} 
              onCheck={(key,showFlag)=>this.setShowList(key,showFlag)} 
              onCheckFinish={()=>this.onCheckFinish()} 
            />
            {/* 帮助 */}
            <Button type="link" href="/download/help.html" target="_blank" style={{ color: '#FFF' }}>
              <QuestionCircleTwoTone />
              帮助
            </Button>
          </div>
        </div>
      </Header>
    );
  }
}

export default enhance(PageHeader);
