import { Layout, Menu, Tabs, Dropdown, Input } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import BaseComponent from 'base/BaseComponent';
import { enhance } from 'base/BaseEnhance';
import React from 'react';
import { connect } from 'react-redux';
import { changeTabPane, deleteTabPane } from 'redux/actions/';
import './Home.less';
import Main from './main/Main';
import PageFooter from './pageFooter/PageFooter';
import PageHeader from './pageHeader/PageHeader';
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary';
import { isEqual } from 'lodash';
import Constants from 'constants/Constants';

const SubMenu = Menu.SubMenu;
const { Sider, Content } = Layout;
const { TabPane } = Tabs;

/**
 * Home页面
 */
class Home extends BaseComponent {
  maxTabNumber = 0;
  layout = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      menuList: [],
      collapsed: false
    };
  }

  componentDidMount() {
    this.getUserMenuList();
    this.getProfileTab();

    this.addTab('首页', Main, '/main', false);
  }

  componentWillUnmount() {
    clearInterval(this.todoFetchInterval);
  }

  getUserMenuList = () => {
    super.sendPostRequest('/system/common/getUserMenuList', {}, (result) => {
      this.setState({
        menuList: result || [],
        allMenuList: result || []
      });
    });
  }

  // 获取可同时开启的tab最大数量
  getProfileTab = () => {
    this.sendPostRequest('/system/common/getProfileByCode', { code: 'SYS_MAX_TAB_NUMBER' }, (result) => {
      this.maxTabNumber = Number(result) || 20;
    });
  }

  /**
   * 菜单渲染
   */
  renderMenu = (menuList) => {
    const subMenus = (subMenuList, menuIndex) => {
      return subMenuList.map((subMenu, index) => {
        return (
          <Menu.Item key={index + '-' + menuIndex} id={subMenu.menuCode} onClick={() => { this.menuClick(subMenu); }}>
            <span>{subMenu.description}</span>
          </Menu.Item>);
      });
    };

    return menuList.map((menu, index) => {
      return (
        <SubMenu key={index} id={menu.menuCode} title={<span><MenuOutlined /><span>{menu.description}</span></span>}>
          {subMenus(menu.subMenuList, index)};
        </SubMenu>);
    });
  }

  /**
   * 菜单点击
   */
  menuClick(menu) {
    if (this.props.tabPanes.length >= this.maxTabNumber) {
      this.showMessage('同时开启标签页过多！', Constants.MESSAGE_LEVEL_WARNING);
      return;
    }

    this.addTabByUrl(menu.description, menu.url, menu.url, true);
  }

  /**
   * tab标签转换
   */
  onChange = activeKey => {
    this.props.changeTabPane(activeKey);
  }

  /**
   * Tab 编辑（删除）
   */
  onEdit = (targetKey, action) => {
    if (action === 'remove') {
      this.props.deleteTabPane(targetKey);
    }
  };

  renderTab(pane) {
    return (React.createElement(pane.content, pane.props || {}));
  }

  onCollapse = () => {
    this.setState(({ collapsed }) => {
      return { collapsed: !collapsed };
    });
  }

  // 右键关闭tab处理
  contextClick = ({ item, key, keyPath, domEvent }) => {
    switch (key) {
      case 'CURRENT':
        this.props.deleteTabPane(this.props.activeKey);
        break;
      case 'ALL':
        for (const tab of this.props.tabPanes) {
          this.props.deleteTabPane(tab.key);
        }
        break;
      case 'LEFT': {
        const activeTabIndex = this.props.tabPanes.findIndex(tab => isEqual(tab.key, this.props.activeKey));
        const closeTabKeyArr = [];
        for (let i = 1; i < activeTabIndex; i++) {
          closeTabKeyArr.push(this.props.tabPanes[i].key);
        }

        for (const closeTabKey of closeTabKeyArr) {
          this.props.deleteTabPane(closeTabKey);
        }
        break;
      }
      case 'RIGHT': {
        const activeTabIndex = this.props.tabPanes.findIndex(tab => isEqual(tab.key, this.props.activeKey));
        const closeTabKeyArr = [];

        for (let i = activeTabIndex; i < this.props.tabPanes.length - 1; i++) {
          closeTabKeyArr.push(this.props.tabPanes[i + 1].key);
        }

        for (const closeTabKey of closeTabKeyArr) {
          this.props.deleteTabPane(closeTabKey);
        }
        break;
      }
      default:
    }
  }

  // 自定义tab点击事件
  renderTabClick = (event) => {
    // 通过鼠标位置获取右键点击元素
    const element = this.getDomElementFromMouseEvent(event);

    // 获取选中元素对应tab对象
    const contextElement = this.props.tabPanes.find(tab => isEqual(tab.title, element.innerHTML));

    if (contextElement != null) {
      this.props.changeTabPane(contextElement.key);
    }
  }

  closeTab = (key) => {
    this.onEdit(key, 'remove');
  }

  // 标题DOM
  title = (title, key) => {
    const tabTitle =
      isEqual(key, '/main')
        ? <span style={{ userSelect: 'none' }}>{title}</span>
        : <><span style={{ userSelect: 'none' }}>{title}</span><span>{' '}</span><CloseOutlined onClick={() => this.closeTab(key)} /></>;
    return tabTitle;
  };

  onMenuSearch = (event) => {
    const { menuList } = this.state;

    const filterMenuList = [];
    for (const menu of menuList) {
      const filterSubMenuList = menu.subMenuList.filter(subMenu => subMenu.description.indexOf(event.target.value) !== -1);
      if (filterSubMenuList != null && filterSubMenuList.length > 0) {
        const filerMenu = Object.assign({}, menu);
        filerMenu.subMenuList = filterSubMenuList;
        filterMenuList.push(filerMenu);
      }
    }
    this.setState({
      menuList: filterMenuList
    });
  }

  onMenuSearchClear = (event) => {
    if (event.target && (event.target.value == null || event.target.value === '')) {
      this.setState({
        menuList: this.state.allMenuList
      });
    }
  }

  render() {
    const { collapsed, menuList } = this.state;

    // 右键菜单
    const menuItems = (
      <Menu onClick={this.contextClick}>
        <Menu.Item key="CURRENT">关闭当前标签</Menu.Item>
        <Menu.Item key="ALL">关闭所有标签</Menu.Item>
        <Menu.Item key="LEFT">关闭左边标签</Menu.Item>
        <Menu.Item key="RIGHT">关闭右边标签</Menu.Item>
      </Menu>
    );

    // 自定义TAB
    const renderTabBar = (props) => {
      // 提取tab信息
      const tabInfo = [];
      // eslint-disable-next-line react/prop-types
      if (props.panes) {
        // eslint-disable-next-line react/prop-types
        props.panes.forEach(item => {
          tabInfo.push({
            key: item.key,
            title: item.props.tab
          });
        });
      }

      return (
        <div className="ywt-tab-wrapper">
          {
            tabInfo.map((item, index) => (
              <div
                key={item.key}
                // eslint-disable-next-line react/prop-types
                className={props.activeKey === item.key ? 'ywt-active-tab ywt-normal-tab ' : 'ywt-normal-tab '}
              >
                {
                  isEqual(item.key, '/main')
                    ? <div style={{ padding: '0 15px' }} onClick={this.renderTabClick.bind(this)}>{item.title}</div>
                    : (
                      <Dropdown overlay={menuItems} trigger={['contextMenu']}>
                        <div
                          style={{ padding: '0 15px' }}
                          onClick={this.renderTabClick.bind(this)}
                          onContextMenu={this.renderTabClick.bind(this)}
                        >
                          {item.title}
                        </div>
                      </Dropdown>
                    )
                }
              </div>
            ))
          }
        </div>
      );
    };

    return (
      <Layout>
        {/* 左侧菜单 */}
        <Sider
          collapsible collapsed={collapsed}
          theme='light' className="ywt-home-sider"
          onCollapse={this.onCollapse}
        >
          <div className="ywt-home-logo">
            {
              collapsed
                ? (<img src="/assets/imgs/logo_white_circle.png" alt="峰禾" style={{ height: '50px', width: '50px' }} />)
                : (<img src="/assets/imgs/logo-white-header.png" alt="峰禾" style={{ height: 'auto', width: '100px' }} />)
            }
          </div>
          <div className={collapsed ? 'ywt-home-menu-search-collapsed' : 'ywt-home-menu-search'}>
            <Input placeholder="菜单查询" allowClear onPressEnter={this.onMenuSearch} onChange={this.onMenuSearchClear}/>
          </div>
          <div className={collapsed ? 'ywt-home-menu-collapsed' : 'ywt-home-menu'}>
            <Menu
              theme="light"
              mode="inline"
              inlineIndent={10}
            >
              {
                this.renderMenu(menuList)
              }
            </Menu>
          </div>
        </Sider>
        {/* 内容展示 */}
        <Layout className={collapsed ? 'ywt-home-layout-collapsed' : 'ywt-home-layout'}>
          <PageHeader isCollapsed={collapsed} getUserMenuList={this.getUserMenuList}/>
          <Content className={collapsed ? 'ywt-home-content-collapsed' : 'ywt-home-content'}>
            <ErrorBoundary>
              <Tabs hideAdd
                onChange={this.onChange}
                activeKey={this.props.activeKey}
                type="editable-card"
                onEdit={this.onEdit}
                destroyInactiveTabPane={false}
                renderTabBar={renderTabBar}
              >
                {
                  this.props.tabPanes.map(pane => (
                    <TabPane tab={this.title(pane.title, pane.key)} key={pane.key} closable={pane.closable}>
                      {this.renderTab(pane)}
                    </TabPane>
                  ))
                }
              </Tabs>
            </ErrorBoundary>
          </Content>
          <PageFooter />
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  activeKey: state.tab.activeKey,
  tabPanes: state.tab.tabPanes
});

const mapDispatchToProps = (dispatch) => ({
  changeTabPane: (activeKey) => dispatch(changeTabPane(activeKey)),
  deleteTabPane: (targetKey) => dispatch(deleteTabPane(targetKey))
});

export default connect(mapStateToProps, mapDispatchToProps)(enhance(Home));
