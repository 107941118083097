import { Layout, Badge, Button, List, Tag, notification } from 'antd';
import BaseComponent from 'base/BaseComponent';
import { enhance } from 'base/BaseEnhance';
import { isEqual } from 'lodash';
import React from 'react';
import './PageFooter.less';

const { Footer } = Layout;

/**
 * 页面页尾
 */
class PageFooter extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      todoList: [],
      todoCount: 0
    };
  }

  componentDidMount() {
    this.getTodoList();
    this.getProfileTodo();
  }

  componentWillUnmount() {
    notification.destroy();
  }

  // 预警提醒刷新间隔时间（秒）
  getProfileTodo = () => {
    this.sendPostRequest('/system/common/getProfileByCode', { code: 'SYS_TODO_INTERVAL_SECONDS' }, (result) => {
      const intervalTime = Number(result) * 1000 || 600000;
      this.todoFetchInterval = setInterval(this.getTodoList, intervalTime);
    });
  }

  getTodoList = () => {
    this.sendGetRequest('/system/todo/getUserTodoList', (result) => {
      const todoList = result || [];
      let todoCount = 0;
      todoList.forEach(value => {
        if (!isNaN(value.extraInfo)) {
          todoCount += Number(value.extraInfo);
        }
      });

      this.setState({
        todoList: todoList,
        todoCount
      }, () => {
        if (todoCount > 0) {
          this.openNotification();
        }
      });
    });
  }

  todoNoticeClick = (todo) => {
    notification.destroy();
    super.addTabByUrl(todo.description, todo.url, todo.url);
  }

  openNotification = () => {
    const { todoList } = this.state;

    const notificationElement = document.getElementsByClassName('ant-notification-notice-content');

    if (notificationElement.length === 1) {
      notification.destroy();
      return;
    }

    const config = {
      message: '预警提醒',
      placement: 'bottomRight',
      bottom: 45,
      duration: null,
      className: 'affix-notification',
      style: { marginBottom: 0, marginRight: -15, backgroundImage: 'linear-gradient(to bottom right, #0876D5, #00B7D7)', padding: '10px 10px' },
      description:
        <List
          size="small"
          bordered
          locale={{ emptyText: '暂无数据' }}
          dataSource={todoList}
          pagination={{ pageSize: 7, size: 'small' }}
          renderItem={item =>
            <List.Item
              key={item.url}
              actions={[<Button key="list-button" type='link' onClick={
                () => this.todoNoticeClick(item)
              }>进入</Button>]}>
              {
                isEqual(item.extraInfo, '0')
                  ? item.description
                  : <span>{item.description}<Tag color="#f50" style={{ marginLeft: 10 }}>{item.extraInfo}</Tag></span>
              }
            </List.Item>}
        />
    };

    notification.open(config);
  };

  render() {
    const { todoCount } = this.state;

    return (
      <Footer className="ywt-dashboard-footer">
        <span style={{ marginRight: '200px' }}></span>
        {/* 固定右下角预警提醒 */}
        <div style={{ position: 'fixed', bottom: 0, height: 30, right: 0 }}>
          <Badge count={todoCount} offset={[-85, 0]} className="affix-button">
            <Button type='primary' onClick={this.openNotification}>预警提醒</Button>
          </Badge>
        </div>
      </Footer>
    );
  }
}

export default enhance(PageFooter);
