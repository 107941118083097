import { message, notification } from 'antd';
import 'common.less';
import Constants from 'constants/Constants';
import { loadViewComponent } from 'modules/ModulerHelper';
import PropTypes from 'prop-types';
import React from 'react';
import { postFile, postRequest, getRequest, downloadRequest } from 'service/HttpCommunicationService';
import SessionService from 'service/SessionService';
import { isEmpty, isEqual } from 'lodash';
import ObjectUtil from 'util/ObjectUtil';
import 'moment/locale/zh-cn';

export default class BaseComponent extends React.Component {
  constructor(props, checkLogin = true) {
    super(props);
    this.checkLogin = checkLogin;
    if (checkLogin && isEmpty(SessionService.userName)) {
      this.jumpToLogin();
    }
  }

  /**
   * 提示显示（notification）
   */
  showNotification(text, type = 'error', placement = 'bottomRight') {
    notification[type]({ message: text, placement });
  }

  /**
   * 提示显示（message）
   */
  showMessage(text, type = 'error') {
    console.log(text);
    message.destroy();
    message.config({
      top: 200,
      duration: 2,
      maxCount: 1
    });

    message[type](text).then(this.addMsgEventListener());
  }

  addMsgEventListener() {
    document.addEventListener('click', this.clickMsgBoxOuter);
  }

  clickMsgBoxOuter = e => {
    // 获取Message的DOM信息
    const messageDomRef = document.querySelector('.ant-message-notice-content');

    // 点击Message外其他地方也可以关闭
    if (messageDomRef && !messageDomRef.contains(e.target)) {
      message.destroy();
    }

    // 移除提示消息监听
    document.removeEventListener('click', this.clickMsgBoxOuter);
  }

  /**
   * POST 请求
   */
  sendPostRequest(url, body, success, failed) {
    if (!this.isRequestValid()) {
      return;
    }
    postRequest(url, body).then(response => {
      this.successResp(response.data, success, failed);
      this.onFinally();
    }).catch(error => {
      this.handleRequestError(error, failed);
      this.onFinally();
    });
    // 兼容低版本浏览器，去掉 ES2018 的finally用法
  }

  /**
   * GET 请求
   */
  sendGetRequest(url, success, failed) {
    if (!this.isRequestValid()) {
      return;
    }
    getRequest(url).then(response => {
      this.successResp(response.data, success, failed);
      this.onFinally();
    }).catch(error => {
      this.handleRequestError(error, failed);
      this.onFinally();
    });
    // 兼容低版本浏览器，去掉 ES2018 的finally用法
  }

  uploadFileRequest(url, body, success, failed) {
    if (!this.isRequestValid()) {
      return;
    }
    postFile(url, body).then(response => {
      this.successResp(response.data, success, failed);
      this.onFinally();
    }).catch(error => {
      this.handleRequestError(error, failed);
      this.onFinally();
    });
    // 兼容低版本浏览器，去掉 ES2018 的finally用法
  }

  // 检查请求是否合法
  isRequestValid = () => {
    if (this.checkLogin && SessionService.userName != null && SessionService.localUserName != null &&
      SessionService.userName !== SessionService.localUserName) {
      SessionService.clear();
      this.jumpToLogin('不能同时登陆两个账号，请重新登录');
      return false;
    } else {
      return true;
    }
  }

  /**
   * 下载并打开窗口
   */
  openDownloadWindow(url, body, failed) {
    const tab = window.open('', '_blank', 'width=800,height=600,menubar=0,toolbar=0');
    tab.document.writeln('下载中,请稍候...');
    downloadRequest(url, body).then(
      (res) => {
        const contentType = res.headers['content-type'];
        const fileBlob = new Blob([res.data], { type: contentType });
        // 下载异常时
        if (res.status !== 200 || contentType == null || contentType.indexOf('text') >= 0 ||
          contentType.indexOf('json') >= 0) {
          tab.close();
          const reader = new FileReader();
          // This fires after the blob has been read/loaded.
          reader.addEventListener('loadend', (e) => {
            console.log(e.srcElement);
            const element = e.srcElement;
            const text = element.result;
            console.log(text);
            if (isEmpty(text)) {
              this.showMessage('下载异常。');
            } else {
              this.showMessage(text);
            }
          });
          reader.readAsText(fileBlob);
        } else {
          const disposition = res.headers['content-disposition'].split(';');
          const matches = /filename=([^;]+)/ig.exec(disposition[1]);
          const fileName = decodeURIComponent(escape(matches[1].trim()));
          console.log(fileName);
          const downloadType = (disposition[0]).trim();
          const fileUrl = window.URL.createObjectURL(fileBlob);
          console.log(fileUrl);

          // PDF 的情况展示文件
          if (contentType.indexOf('pdf') >= 0 && downloadType != null && downloadType.indexOf('inline') >= 0) {
            if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
              window.navigator.msSaveOrOpenBlob(fileBlob, fileName);
              tab.close();
              this.showMessage('请保存后，查看', 'success');
            } else {
              tab.location.href = fileUrl;
            }
          } else {
            tab.close();
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = fileUrl;
            a.download = fileName;
            a.click();
            a.addEventListener('click', () => {
              // 延迟提示下载成功，给用户选择下载路径的时间
              setTimeout(() => {
                console.log('----------------------------------');
                this.showMessage('下载成功，请确认', 'success');
              }, 1000); // 延迟时间根据实际情况调整
            });
            window.URL.revokeObjectURL(fileUrl);
            a.remove(); // remove the element
          }
        }
      }
    ).catch((err) => {
      tab.close();
      console.log(err);
      this.onError('下载文件失败', failed);
    });
  }

  successResp(response, success, failed) {
    // 返回数据异常时
    if (response == null || response.code == null) {
      this.onError('返回数据异常，请重新提交', failed);
    } else {
      switch (response.code) {
        case Constants.RESPONSE_CODE_SUCCESS:
          if (!ObjectUtil.isEmpty(success)) {
            success(response.result);
          }
          break;
        default:
          this.onError(response.errorMsg, failed);
          break;
      }
    }
  }

  handleRequestError(error, failed) {
    if (error.response == null) {
      this.onError(error.message, failed);
    } else {
      const response = error.response;
      switch (response.status) {
        // UNAUTHORIZED
        case 401:
          this.jumpToLogin(response.data);
          break;
        // FORBIDDEN
        case 403:
          this.onError(response.data, failed);
          break;
        default:
          this.onError('服务器异常。(' + response.status + ')' + (response.data.errorMsg ? response.data.errorMsg : response.data), failed);
          break;
      }
    }
  }

  /**
   * 错误消息
   */
  onError(errorMessage, failed) {
    if (failed != null) {
      failed(errorMessage);
    } else {
      this.showMessage(errorMessage);
    }
  }

  /**
   * 收尾处理
   */
  onFinally() {

  }

  /**
   * 调整到登录页面
   */
  jumpToLogin(errorMessage) {
    if (!isEmpty(errorMessage)) {
      this.showMessage(errorMessage);
    }
    this.props.history.push('/login');
  }

  /**
   * 添加Tab
   * title: 标题, content: 显示的组件, key: 唯一标识, closable: 是否可以关闭, props: 传递给组件的参数
   */
  addTab(title, content, key, closable = true, props) {
    this.props.addTabPane({ title, content, key, closable, props });
  }

  /**
   * 根据URL添加Tab页
   * validateCallback: 有效检查，失败(false)时不加载页面
   */
  addTabByUrl(title, url, key, closable) {
    if (isEmpty(url)) {
      this.showMessage('菜单URL未配置，请联系联系管理员');
      return;
    }
    // 解析参数
    var urlParts = url.split('?');
    var props = null;
    if (urlParts.length > 1) {
      props = JSON.parse(urlParts[1]);
    }

    props = props || {};
    props.url = url;

    this.addTab(title, loadViewComponent(urlParts[0]), key, closable, props);
  }

  /**
   * 根据PATH添加Tab页
   */
  addTabByPath(title, path, key, props, closable) {
    this.addTab(title, loadViewComponent(path), key, closable, props || {});
  }

  /**
 * 删除Tab
 * key: 唯一标识
 */
  deleteTabPane(key) {
    this.props.deleteTabPane(key);
  }

  // 通过鼠标点击位置获取DOM元素
  getDomElementFromMouseEvent(mouseEvent) {
    const x = mouseEvent.pageX || mouseEvent.clientX + document.body.scroolLeft;
    const y = mouseEvent.pageY || mouseEvent.clientY + document.body.scrollTop;
    return document.elementFromPoint(x, y);
  }

  // 检查是否点击表格单选框
  checkClickTableRadio(mouseEvent) {
    if (mouseEvent == null) {
      return false;
    }
    let checkFlag = false;
    const element = this.getDomElementFromMouseEvent(mouseEvent);

    if (element) {
      if (element.className.includes('ant-radio') ||
        element.className.includes('ant-checkbox') ||
        element.className.includes('ant-table-selection-column')) {
        checkFlag = true;
      }
    }

    return checkFlag;
  }

  // 检查是否鼠标点击
  checkMouseOrNot(event) {
    if (event == null) {
      return false;
    }

    if (event.screenX > 0) {
      return true;
    }
    return false;
  }

  // 键盘操作相关方法
  handleKeyUp = (event, params) => {
    const { inputRef, total, currentPage, pageSize, sortString, onPageChange, addAction } = params;

    // 焦点在检索框时、键盘只能录入信息
    if (inputRef && inputRef.current && inputRef.current.state && inputRef.current.state.focused) {
      return;
    }

    // 获取表格选中radio的焦点
    let checkedRef = null;
    if (document.querySelector('.ant-radio-checked')) {
      checkedRef = document.querySelector('.ant-radio-checked').querySelector('.ant-radio-input');
      checkedRef.focus();

      switch (event.keyCode) {
        // 小键盘【+】、向后翻页
        case Constants.KEYBOARD_NUMBERPAD_ADD:
          if (currentPage < Math.ceil(total / 10)) {
            onPageChange(sortString, currentPage + 1, pageSize);
          }
          break;
        // 小键盘【-】、向前翻页
        case Constants.KEYBOARD_NUMBERPAD_SUBTRACT:
          if (currentPage > 1) {
            onPageChange(sortString, currentPage - 1, pageSize);
          }
          break;
        // 选中明细行后、回车键添加
        case Constants.KEYBOARD_ENTER:
          addAction(Constants.ACTION_KEYBOARD);
          break;
        default:
      }
    }
  }

  // 查询弹出框获取焦点后、清空选中明细
  handleMouseDown = (event, tableRef, inputId) => {
    const element = this.getDomElementFromMouseEvent(event);
    if (isEqual(element.id, inputId)) {
      tableRef && tableRef.setSelectRows();
    }
  }

  // 选中最后一行输入框
  setLastRowFocus = (lastInputNumberRef) => {
    // 数量输入框参照不为空时、执行下面操作
    if (lastInputNumberRef != null) {
      // 最后一行数量为0时、把输入框值设置为空
      if (lastInputNumberRef.state.value === 0) {
        lastInputNumberRef.state.value = null;
        lastInputNumberRef.state.inputValue = null;
      }
      // 最后一行数量值选中
      lastInputNumberRef.input.select();
    }
  }

  refresh = () => {
    this.setState({});
  }
}

BaseComponent.propTypes = {
  history: PropTypes.object.isRequired,
  addTabPane: PropTypes.func.isRequired,
  deleteTabPane: PropTypes.func.isRequired,
  // 组件 URL，用于标识组件唯一性
  url: PropTypes.string
};
