/**
 * 模块路由配置
 */
import { subRoutes as finCreditRoutes } from './finCredit/FinCreditModule';
import { subRoutes as financeNewRoutes } from './financeNew/FinanceNewModule';

export const routes = [
  // 旧版菜单
  {
    path: '/finCredit',
    subRoutes: finCreditRoutes
  },
  // 新版菜单
  {
    path: '/financeNew',
    subRoutes: financeNewRoutes
  }
];
