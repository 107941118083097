
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addTabPane, deleteTabPane } from 'redux/actions/';

export function enhance(WrappedComponent) {
  return addTabReduxSupport(addRouteSupport(WrappedComponent));
}

export function addRouteSupport(WrappedComponent) {
  return withRouter(WrappedComponent);
}

const mapAddTabDispatchToProps = (dispatch) => ({
  addTabPane: (tabPane) => dispatch(addTabPane(tabPane)),
  deleteTabPane: (tabPane) => dispatch(deleteTabPane(tabPane))
});

export function addTabReduxSupport(WrappedComponent) {
  return connect(null, mapAddTabDispatchToProps)(WrappedComponent);
}
