/**
 * 前端的 Session 服务
 */
// 登录错误计数
const ITEM_KEY_LOGIN_ERROR_CNT = 'loginErrorCnt';
// 用户名
const ITEM_KEY_USER_NAME = 'userName';
// 代理用户名称
const ITEM_KEY_PROXY_USER_NAME = 'proxyUserName';
// 登录类型（0：系统，1：平台，2：医院，3：供应商）
const ITEM_KEY_SYSTEM_TYPE = 'systemType';
// 仓库ID
const ITEM_KEY_WAREHOUSE_ID = 'warehouseId';
// 仓库类型
const ITEM_KEY_WAREHOUSE_TYPE = 'warehouseType';
// Token
const ITEM_KEY_TOKEN_ID = 'gcO3RAI&m';
// 公告显示标识
const ITEM_KEY_NOTICE_SHOW_FLAG = 'noticeShowFlag';

export default class SessionService {
  static get userName() {
    return sessionStorage.getItem(ITEM_KEY_USER_NAME);
  }

  static set userName(str) {
    if (str == null) {
      sessionStorage.removeItem(ITEM_KEY_USER_NAME);
    } else {
      sessionStorage.setItem(ITEM_KEY_USER_NAME, str);
    }
  }

  static get localUserName() {
    return localStorage.getItem(ITEM_KEY_USER_NAME);
  }

  static set localUserName(str) {
    if (str == null) {
      localStorage.removeItem(ITEM_KEY_USER_NAME);
    } else {
      localStorage.setItem(ITEM_KEY_USER_NAME, str);
    }
  }

  static get loginErrorCnt() {
    const cntStr = sessionStorage.getItem(ITEM_KEY_LOGIN_ERROR_CNT);
    if (cntStr == null) {
      return 0;
    } else {
      return Number(cntStr);
    }
  }

  static set loginErrorCnt(v) {
    sessionStorage.setItem(ITEM_KEY_LOGIN_ERROR_CNT, String(v));
  }

  static get proxyUserName() {
    return sessionStorage.getItem(ITEM_KEY_PROXY_USER_NAME);
  }

  static set proxyUserName(v) {
    if (v == null) {
      sessionStorage.removeItem(ITEM_KEY_PROXY_USER_NAME);
    } else {
      sessionStorage.setItem(ITEM_KEY_PROXY_USER_NAME, v);
    }
  }

  static get systemType() {
    return Number(sessionStorage.getItem(ITEM_KEY_SYSTEM_TYPE));
  }

  static set systemType(v) {
    sessionStorage.setItem(ITEM_KEY_SYSTEM_TYPE, String(v));
  }

  static get warehouseId() {
    return sessionStorage.getItem(ITEM_KEY_WAREHOUSE_ID);
  }

  static set warehouseId(v) {
    sessionStorage.setItem(ITEM_KEY_WAREHOUSE_ID, v);
  }

  static get warehouseType() {
    return Number(sessionStorage.getItem(ITEM_KEY_WAREHOUSE_TYPE));
  }

  static set warehouseType(v) {
    sessionStorage.setItem(ITEM_KEY_WAREHOUSE_TYPE, v);
  }

  static get token() {
    return localStorage.getItem(ITEM_KEY_TOKEN_ID);
  }

  static set token(v) {
    if (v == null) {
      localStorage.removeItem(ITEM_KEY_TOKEN_ID);
    } else {
      localStorage.setItem(ITEM_KEY_TOKEN_ID, v);
    }
  }

  static get noticeShowFlag() {
    return Number(sessionStorage.getItem(ITEM_KEY_NOTICE_SHOW_FLAG));
  }

  static set noticeShowFlag(v) {
    sessionStorage.setItem(ITEM_KEY_NOTICE_SHOW_FLAG, v);
  }

  static clear() {
    this.userName = null;
    this.localUserName = null;
    this.proxyUserName = null;
    this.systemType = null;
    this.warehouseId = null;
    this.token = null;
    this.noticeShowFlag = null;
  }
}
