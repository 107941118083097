import React from 'react';
import BaseComponent from 'base/BaseComponent';
import { enhance } from 'base/BaseEnhance';

class ErrorBoundary extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    console.log('Error: ' + error);
    console.log('Error info:' + JSON.stringify(errorInfo));
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo.componentStack);
  }

  render() {
    if (this.state.error) {
      if (this.state.error.code === 'MODULE_NOT_FOUND') {
        return (
          <div>
            <h2>菜单URL配置异常</h2>
          </div>
        );

      // 加载错误
      } else if (this.state.error.toString().startsWith('ChunkLoadError')) {
        super.showMessage('后台程序已经更新，即将自动刷新页面');
        setTimeout(() => {
          window.location.reload();
        }, 2000);

        return (
          <div>
            <h2>后台程序已经更新，请刷新页面</h2>
          </div>
        );
      } else {
        return (
          <div>
            <h2>系统异常，请刷新后重试并联系管理员</h2>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error.toString()}
            </details>
          </div>);
      }
    }

    return this.props.children;
  }
}

export default enhance(ErrorBoundary);
