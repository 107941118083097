/**
 * 模块相关帮助类
 */

import { routes } from './RouteConfig';
import loadable from '@loadable/component';

const routeMap = {};

const init = () => {
  routes.forEach((route) => {
    route.subRoutes.forEach((subRoute) => {
      routeMap[route.path + subRoute.path] = subRoute.component;
    });
  });
};

init();

const loadComponent = (url) => {
  return loadable(() => import(`${url}`));
};

export function loadViewComponent(url) {
  var component = routeMap[url];
  if (component == null) {
    component = loadComponent(url);
    routeMap[url] = component;
  }
  return component;
}
