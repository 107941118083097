import { enhance } from 'base/BaseEnhance';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import SessionService from 'service/SessionService';
import BaseComponent from 'base/BaseComponent';
import PrivilegeService from 'service/PrivilegeService';

class PrivateRoute extends BaseComponent {
  constructor(props) {
    super(props, false);
    this.state = {
      auth: SessionService.userName != null
    };
  }

  componentDidMount() {
    if (SessionService.userName == null &&
      SessionService.token != null) {
      super.sendPostRequest('/loginByToken', { token: SessionService.token }, (result) => {
        if (result == null) {
          SessionService.token = null;
        } else {
          this.onLoginSuccess(result);
          this.setState({
            auth: true
          });
        }
      });
    }
  }

  onLoginSuccess = (result) => {
    // 登录成功后，将错误计数改为0
    SessionService.loginErrorCnt = 0;
    SessionService.userName = result.userName;
    SessionService.localUserName = result.userName;
    SessionService.proxyUserName = result.proxyUserName;
    SessionService.systemType = result.systemType;
    SessionService.token = result.longTermToken;
    PrivilegeService.disabledPrivilegeInfo = result.disabledFrontPrivilegePathList;
    this.showNotification('欢迎' + result.userName, 'success');

    if (result.resetPassword != null) {
      this.props.history.push('/', { resetPassword: '1' });
    } else {
      this.props.history.push('/');
    }
  }

  render() {
    const { children, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={() => this.state.auth ? (children) : (
          <Redirect to={{ pathname: '/login' }}/>
        )
        }
      />
    );
  }
}

export default enhance(PrivateRoute);

PrivateRoute.propTypes = {
  children: PropTypes.element.isRequired
};
