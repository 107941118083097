import loadable from '@loadable/component';

// 懒加载组件
const CreditApplicationManagement = loadable(() => import('./creditApplicationManagement/CreditApplicationManagement'));
const InReceiptQuery = loadable(() => import('./inReceiptQuery/InReceiptQuery'));
const OutReceiptQuery = loadable(() => import('./outReceiptQuery/OutReceiptQuery'));
const SupplierQuery = loadable(() => import('./supplierQuery/SupplierQuery'));
const OrderQuery = loadable(() => import('./orderQuery/OrderQuery'));
const DeliveryBalanceQuery = loadable(() => import('./deliveryBalanceQuery/DeliveryBalanceQuery'));
const SupplierStatementQuery = loadable(() => import('./supplierStatementQuery/SupplierStatementQuery'));
const CstStatementModify = loadable(() => import('./supplierStatementQuery/cstStatementModify/CstStatementModify'));
const UseReceiptQuery = loadable(() => import('./useReceiptQuery/UseReceiptQuery'));
const HospitialCreditQuery = loadable(() => import('./hospitialCreditQuery/HospitialCreditQuery'));
const SyncDataManagement = loadable(() => import('./syncDataManagement/SyncDataManagement'));
const InvoiceQuery = loadable(() => import('./invoiceQuery/InvoiceQuery'));
const SupplierDetail = loadable(() => import('./supplierQuery/supplierDetail/SupplierDetail'));
const DocumentApproveManagement = loadable(() => import('./documentApproveManagement/DocumentApproveManagement'));
const CollectionInfoModal = loadable(() => import('./documentApproveManagement/collectionInfoModal/CollectionInfoModal'));
const ZdResult = loadable(() => import('./documentApproveManagement/zdResult/ZdResult'));
const LawResult = loadable(() => import('./documentApproveManagement/lawResult/LawResult'));
const RepaymentBookManagement = loadable(() => import('./repaymentBookManagement/RepaymentBookManagement'));
const RepaymentBookDetailModify = loadable(() => import('./repaymentBookManagement/repaymentBookDetailModify/RepaymentBookDetailModify'));
const ProfileManagement = loadable(() => import('./profileManagement/ProfileManagement'));
const Creditmanagement = loadable(() => import('./creditmanagement/Creditmanagement'));
const Financingmanagement = loadable(() => import('./financingmanagement/Financingmanagement'));

export const subRoutes = [
  {
    path: '/CreditApplicationManagement',
    component: CreditApplicationManagement
  },
  {
    path: '/InReceiptQuery',
    component: InReceiptQuery
  },
  {
    path: '/OutReceiptQuery',
    component: OutReceiptQuery
  },
  {
    path: '/SupplierQuery',
    component: SupplierQuery
  },
  {
    path: '/OrderQuery',
    component: OrderQuery
  },
  {
    path: '/DeliveryBalanceQuery',
    component: DeliveryBalanceQuery
  },
  {
    path: '/SupplierStatementQuery',
    component: SupplierStatementQuery
  },
  {
    path: '/CstStatementModify',
    component: CstStatementModify
  },
  {
    path: '/UseReceiptQuery',
    component: UseReceiptQuery
  },
  {
    path: '/HospitialCreditQuery',
    component: HospitialCreditQuery
  },
  {
    path: '/SyncDataManagement',
    component: SyncDataManagement
  },
  {
    path: '/InvoiceQuery',
    component: InvoiceQuery
  },
  {
    path: '/DocumentApproveManagement',
    component: DocumentApproveManagement
  },
  {
    path: '/CollectionInfoModal',
    component: CollectionInfoModal
  },
  {
    path: '/ZdResult',
    component: ZdResult
  },
  {
    path: '/LawResult',
    component: LawResult
  },
  {
    path: '/SupplierDetail',
    component: SupplierDetail
  },
  {
    path: '/RepaymentBookManagement',
    component: RepaymentBookManagement
  },
  {
    path: '/RepaymentBookDetailModify',
    component: RepaymentBookDetailModify
  },
  {
    path: '/ProfileManagement',
    component: ProfileManagement
  },
  // 授信管理
  {
    path: '/Creditmanagement',
    component: Creditmanagement
  },
  // 融资管理
  {
    path: '/Financingmanagement',
    component: Financingmanagement
  }
];
