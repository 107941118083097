export default class Constants {
  static BACK_HOST_URI_PREFIX = '/spd/';

  /** 返回结果代码-正常 */
  static RESPONSE_CODE_SUCCESS = '1';

  /** 错误消息代码-需要验证码 */
  static ERROR_CODE_NEED_CAPTCHA = 'EWCOM003';

  // 不包含验证码的最大登录失败次数
  static MAX_LOGIN_ERROR_CNT_WITHOUT_CAPTCHA = 1;

  static MESSAGE_LEVEL_WARNING = 'warning';

  // 排序MAP
  static sortMap = {
    ascend: 'ASC',
    descend: 'DESC'
  };

  // 操作类型--添加
  static ACTION_TYPE_ADD = 0;
  // 操作类型--复制添加
  static ACTION_TYPE_COPY_ADD = 1;
  // 操作类型--删除
  static ACTION_TYPE_DELETE = 2;
  // 操作类型--编辑
  static ACTION_TYPE_EDIT = 3;

  // 预置标志--预置
  static PREDEFINED_FLAG_YES = 1;
  // 预置标志--非预置
  static PREDEFINED_FLAG_NO = 0;

  // 操作-添加、复制添加
  static UPDATE_FLAG_CREATE = 1;
  // 操作-编辑
  static UPDATE_FLAG_MODIFY = 2;
  // 操作-删除
  static UPDATE_FLAG_DELETE = 3;

  // 系统类型:0系统
  static SYSTEM_TYPE_SYS = 0;
  // 系统类型:1平台
  static SYSTEM_TYPE_PLAT = 1;
  // 系统类型:3供应商
  static SYSTEM_TYPE_SUP = 3;
  // 系统类型:4客户
  static SYSTEM_TYPE_CUS = 4;

  /** 发货单状态-新建  */
  static DELIVERY_STATUS_NEW = 0;
  /** 发货单状态-待验收  */
  static DELIVERY_STATUS_ACCEPT_WAIT = 1;
  /** 发货单状态-已拒收 */
  static DELIVERY_STATUS_REJECTED = 2;
  /** 发货单状态-待入库 */
  static DELIVERY_STATUS_STORAGE_WAIT = 3;
  /** 发货单状态-取消 */
  static DELIVERY_STATUS_CANCEL = 18;
  /** 发货单状态-已完成 */
  static DELIVERY_STATUS_COMPLETE = 19;
  /** 发货单状态-过期 */
  static DELIVERY_STATUS_PAST_DUE = 20;

  /** 发货单类型-发货单 **/
  static DELIVERY_TYPE_DELIVERY = 1;
  /** 发货单类型-退货单 **/
  static DELIVERY_TYPE_RETURN = 2;
  /** 发货单类型-主动发货 **/
  static DELIVERY_TYPE_INITIATIVE = 3;

  /** 仓库类型-一级库 **/
  static WAREHOUSE_TYPE_WAREHOUSE = 1;
  /** 仓库类型-科室 **/
  static WAREHOUSE_TYPE_DEPARTMENT = 2;
  /** 仓库类型-供应室 **/
  static WAREHOUSE_TYPE_SUPPLIER = 3;
  /** 仓库类型-小组 **/
  static WAREHOUSE_TYPE_TEAM = 4;

  /** 组套审核状态-新建 **/
  static ITEMSET_STATUS_NEW = 0;
  /** 组套审核状态-待审核 **/
  static ITEMSET_STATUS_WAIT = 1;
  /** 组套审核状态-正常 **/
  static ITEMSET_STATUS_APPROVAL = 2;
  /** 组套审核状态-已驳回 **/
  static ITEMSET_STATUS_REJECT = 3;

  /** 骨科包状态-空置 **/
  static OPERATION_BOX_STATUS_EMPTY = 0;
  /** 骨科包状态-待提交 **/
  static OPERATION_BOX_STATUS_WAIT_SUBMIT = 1;
  /** 骨科包状态-待验收 **/
  static OPERATION_BOX_STATUS_WAIT_ACCEPT = 2;
  /** 骨科包状态-已驳回 **/
  static OPERATION_BOX_STATUS_REJECTED = 3;
  /** 骨科包状态-已验收 **/
  static OPERATION_BOX_STATUS_ACCEPTED = 5;
  /** 骨科包状态-已使用 **/
  static OPERATION_BOX_STATUS_USED = 6;

  /** 单据系统类型:期初导入 */
  static RECEIPT_TYPE_INITIAL_IMPORT = 0;
  /** 单据系统类型:手动入库 */
  static RECEIPT_TYPE_OPERATION_IMPORT = 1;
  /** 单据系统类型:采购入库 */
  static RECEIPT_TYPE_PURCHASE_IMPORT = 2;
  /** 单据系统类型:其他入库 */
  static RECEIPT_TYPE_OTHER_IMPORT = 3;
  /** 单据系统类型:仓库盘盈 */
  static RECEIPT_TYPE_WAREHOUSE_PROFIT = 4;
  /** 单据系统类型:手动出库 */
  static RECEIPT_TYPE_OPERATION_EXPORT = 5;
  /** 单据系统类型:请领出库 */
  static RECEIPT_TYPE_REQUISITION_EXPORT = 6;
  /** 单据系统类型:仓库盘亏 */
  static RECEIPT_TYPE_WAREHOUSE_LOSSES = 7;
  /** 单据系统类型:采购退货 */
  static RECEIPT_TYPE_PURCHASE_RETURN = 8;
  /** 单据系统类型:科室消耗 */
  static RECEIPT_TYPE_DEPARTMENT_USE = 10;
  /** 单据系统类型:科室借用 */
  static RECEIPT_TYPE_DEPARTMENT_TRANSFER = 11;
  /** 单据系统类型:销售退货 */
  static RECEIPT_TYPE_DEPARTMENT_RETURN = 12;
  /** 单据系统类型:库存调拨 */
  static RECEIPT_TYPE_INVENTORY_ALLOT = 13;
  /** 单据系统类型:仓库报损 */
  static RECEIPT_TYPE_WAREHOUSE_DAMAGE = 15;

  /** 单据状态:新建 **/
  static RECEIPT_STATUS_NEW = 0;
  /** 单据状态:已拒收 **/
  static RECEIPT_STATUS_REFUSED = 2;
  /** 单据状态:已驳回 **/
  static RECEIPT_STATUS_REJECT = 4;
  /** 单据状态:待验收 **/
  static RECEIPT_STATUS_WAIT_ACCEPT = 10;
  /** 单据状态:已验收 **/
  static RECEIPT_STATUS_ACCEPTED = 11;
  /** 单据状态:待出库 **/
  static RECEIPT_STATUS_WAIT_EXPORT = 12;
  /** 单据状态:已出库 **/
  static RECEIPT_STATUS_EXPORTED = 13;
  /** 单据状态:待审核 **/
  static RECEIPT_STATUS_WAIT_APPROVE = 14;
  /** 单据状态:待确认 **/
  static RECEIPT_STATUS_WAIT_CONFIRM = 15;
  /** 单据状态:取消 **/
  static RECEIPT_STATUS_CANCEL = 18;
  /** 单据状态:完成 **/
  static RECEIPT_STATUS_COMPLETE = 19;
  /** 单据状态:过期 **/
  static RECEIPT_STATUS_EXPIRE = 20;

  /** 订单状态-未响应 */
  static ORDER_STATUS_NEW = 0
  /** 订单状态-已响应 */
  static ORDER_STATUS_RESPONSED = 1
  /** 订单状态-取消 */
  static ORDER_STATUS_CANCEL = 18
  /** 订单状态-完成 */
  static ORDER_STATUS_FINISH = 19
  /** 订单状态-过期 */
  static ORDER_STATUS_PAST_DUE = 20

  /** 自动补货标识:手动 **/
  static AUTO_FLAG_MANUAL = 0;
  /** 自动补货标识:自动 **/
  static AUTO_FLAG_AUTO = 1;

  /** 紧急程度:普通 */
  static EMERGENCY_NORMAL = 1;
  /** 紧急程度:紧急 */
  static EMERGENCY_URGENT = 2;

  /** 验收类型-普通发货单 **/
  static ACCEPTANCE_TYPE_DELIVERY = 1;
  /** 验收类型-手动入库单 **/
  static ACCEPTANCE_TYPE_OPERATION_IMPORT = 2;
  /** 验收类型-备货发货单 **/
  static ACCEPTANCE_TYPE_STOCKUP_DELIVERY = 3;

  /** ========= 键盘操作 START ========= */
  /** 回车 */
  static KEYBOARD_ENTER = 13
  /** 上箭头 */
  static KEYBOARD_UP_ARROW = 38
  /** 下箭头 */
  static KEYBOARD_DOWN_ARROW = 40
  /** 小键盘 加号 */
  static KEYBOARD_NUMBERPAD_ADD = 107
  /** 小键盘 减号 */
  static KEYBOARD_NUMBERPAD_SUBTRACT = 109

  /** 显示明细方式 -- 首次打开 */
  static ENTER_SPOT_SHOW = 1;
  /** 显示明细方式 -- 打开后查询显示 */
  static ENTER_SPOT_SEARCH = 2;
  /** 操作方式 -- 鼠标 */
  static ACTION_MOUSE = 1;
  /** 操作方式 -- 键盘 */
  static ACTION_KEYBOARD = 2;
  /** ========= 键盘操作 END ========= */

  /** 单据类型:备货入库 */
  static RECEIPT_TYPE_STOCKUP_IN = 1;
  /** 单据类型:备货出库 */
  static RECEIPT_TYPE_STOCKUP_OUT = 2;
  /** 单据类型:备货退货 */
  static RECEIPT_TYPE_STOCKUP_RETURN_SUPPLIER = 3;
  /** 单据类型:备货退库 */
  static RECEIPT_TYPE_STOCKUP_RETURN_WAREHOUSE = 4;
  /** 单据类型:科室消耗 */
  static RECEIPT_TYPE_STOCKUP_CONSUMPTION = 5;

  /** 单据类型:（备货退库）退库接收 */
  static RECEIPT_TYPE_STOCKUP_RETURN_WAREHOUSE_RECEIVING = 1;

  /** 备货申请状态:新建 **/
  static STOCKUP_STATUS_NEW = 0;
  /** 备货申请状态:库房待审核 **/
  static STOCKUP_STATUS_WAREHOUSE_WAIT_APPROVAL = 1;
  /** 备货申请状态:采购待审核 **/
  static STOCKUP_STATUS_PURCHASE_WAIT_APPROVAL = 2;
  /** 备货申请状态:已驳回 **/
  static STOCKUP_STATUS_REJECT = 3;
  /** 备货申请状态:完成 **/
  static STOCKUP_STATUS_COMPLETE = 19;
  /** 备货申请状态:过期 **/
  static STOCKUP_STATUS_EXPIRED = 20;

  /** 备货单据状态:新建 */
  static STOCKUP_RECEIPT_STATUS_NEW = 0;
  /** 备货单据状态:待审核 */
  static STOCKUP_RECEIPT_STATUS_WAIT = 1;
  /** 备货单据状态:已驳回 */
  static STOCKUP_RECEIPT_STATUS_REJECT = 2;
  /** 备货单据状态:已验收 */
  static STOCKUP_RECEIPT_STATUS_ACCEPT = 3;
  /** 备货单据状态:已出库 */
  static STOCKUP_RECEIPT_STATUS_DELIVERIED = 5;
  /** 备货单据状态:完成 */
  static STOCKUP_RECEIPT_STATUS_COMPLETE = 19;
  /** 备货单据状态:过期 */
  static STOCKUP_RECEIPT_STATUS_OVERDUE = 20;

  /** 盘点单状态:正在盘点 */
  static COUNTING_STATUS_COUNTING = 1;
  /** 盘点单状态:待审核 */
  static COUNTING_STATUS_APPROVAL_WAIT = 2;
  /** 盘点单状态:取消 */
  static COUNTING_STATUS_CANCEL = 18;
  /** 盘点单状态:完成 */
  static COUNTING_STATUS_COMPLETE = 19;
  /** 盘点单状态:过期 */
  static COUNTING_STATUS_OVERDUE = 20;

  /** 手术耗材状态:新建 */
  static OPERATION_ITEM_STATUS_NEW = 0;
  /** 手术耗材状态:已确认 */
  static OPERATION_ITEM_STATUS_CONFIRMED = 1;
  /** 手术耗材状态:计费失败 */
  static OPERATION_ITEM_STATUS_CHARGE_FAILURE = 2;
  /** 手术耗材状态:完成 */
  static OPERATION_ITEM_STATUS_COMPLETE = 19;
  /** 手术耗材状态:过期 */
  static OPERATION_ITEM_STATUS_OVERDUE = 20;

  /** 手术耗材使用方式:库存 */
  static OPERATION_USAGE_WAY_INVENTORY = 1;
  /** 手术耗材使用方式:备货 */
  static OPERATION_USAGE_WAY_STOCKUP = 2;
  /** 手术耗材使用方式:直接使用 */
  static OPERATION_USAGE_WAY_DIRECTUSE = 3;
  /** 手术耗材使用方式:手术包 */
  static OPERATION_USAGE_WAY_BOX = 4;

  /** 条码类型: 主码 */
  static BARCODE_TYPE_MAINBARCODE = 1;
  /** 条码类型: 医院条码 */
  static BARCODE_TYPE_HOSPITAL_BARCODE = 2;

  /** 菜单审核状态标识 --  0：没审 **/
  static MENU_ITEM_DONE_FLAG_NO = 0;
  /** 菜单审核状态标识 --  1：已审 **/
  static MENU_ITEM_DONE_FLAG_YES = 1;

  /** 菜单标题审核状态标识 --  0：全没审 **/
  static SUB_MENU_DONE_FLAG_NONE = 0;
  /** 菜单标题审核状态标识 --  1：部分审 **/
  static SUB_MENU_DONE_FLAG_PART = 1;
  /** 菜单标题审核状态标识 --  2：全部审 **/
  static SUB_MENU_DONE_FLAG_ALL = 2;

  /** 运营人员评估字段类型 -- 1：人员评价字段 */
  static SYS_EVALUATION_ITEM_TYPE_OPERATOR_EVALUATION = 1;
  /** 运营人员评估字段类型 -- 2：评价公司名称 */
  static SYS_EVALUATION_ITEM_TYPE_COMPANY_NAME = 2;
  /** 运营人员评估字段类型 -- 3：评价人员名称 */
  static SYS_EVALUATION_ITEM_TYPE_OPERATOR_NAME =3;
};
