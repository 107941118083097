
import axios from 'axios';
import Constants from 'constants/Constants';

axios.interceptors.request.use(function(config) {
  // Logging.debug('Request is: ' + config.url + ' -- ' + JSON.stringify(config.data));
  return config;
}, function(error) {
  // Logging.debug('Error is: ' + JSON.stringify(error));
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function(response) {
  var xsrfToken = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
  if (xsrfToken != null) {
    axios.defaults.headers.post['X-XSRF-TOKEN'] = xsrfToken;
  }
  return response;
}, function(error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Logging.debug('Response is: ' + JSON.stringify(error.message));
  return Promise.reject(error);
});

export function getRequest(url) {
  return axios.get(Constants.BACK_HOST_URI_PREFIX + url);
}

export function postRequest(url, body, headers = {}) {
  headers['Content-Type'] = 'application/json';
  return axios.post(Constants.BACK_HOST_URI_PREFIX + url, body, { headers: headers });
}

export function postFile(url, body) {
  return axios.post(Constants.BACK_HOST_URI_PREFIX + url, body);
}

export function downloadRequest(url, body) {
  return axios.post(Constants.BACK_HOST_URI_PREFIX + url, body, {
    headers: {
      'Content-Type': 'application/json'
    },
    responseType: 'blob'
  });
}
