import React from 'react';
import { Modal, Input, Checkbox, Button, } from 'antd';
import YWTSimpleTable from 'components/ywtTable/YWTSimpleTable';
import BaseComponent from 'base/BaseComponent';
import { enhance } from 'base/BaseEnhance';

const { Search, TextArea } = Input;


/**
 * 主页头部显示区域 -- 历史公告弹出框
 */
class NoticeModal extends BaseComponent {
  searchValues = {
    currentPage: 1,
    pageSize: 10,
    sortString: '',
    searchValueLike: null
  }

  constructor(props) {
    super(props);
    this.state = {
      noticeActiveModalVisible: false,
      noticeActiveList: [],
      idList: [],
      isLoading: false,
      modalVisible: false,
      dataSource: [],
      total: 0
    };

    this.columns = [
      {
        title: '公告代码',
        key: 'noticeCode',
        dataIndex: 'noticeCode',
        fixed: true,
        width: 150
      },
      {
        title: '公告标题',
        key: 'noticeName',
        dataIndex: 'noticeName',
        fixed: true,
        width: 200
      },
      {
        title: '公告期间起',
        key: 'startDate',
        dataIndex: 'startDate',
        width: 150
      },
      {
        title: '公告期间止',
        key: 'endDate',
        dataIndex: 'endDate',
        width: 150
      }
    ];
  }

  componentDidMount() {
    this.props.onRef(this);
    this.getNoticeActiveData(); // 获取有效公告
    this.getNoticeHistoryData();
  }

  showNoticeHistoryData = () => {
    this.setState({
      modalVisible: true,
      dataSource: []
    });
    this.getNoticeHistoryData();
  }

  getNoticeHistoryData = () => {
    this.setState({
      isLoading: true
    });
    this.sendPostRequest('/system/notice/getNoticeHistoryList', this.searchValues, (result) => {
      if (result != null) {
        this.setState({
          dataSource: result.infoList || [],
          total: result.totalRecord
        });
      }
    });
  }

  onFinally() {
    this.setState({
      isLoading: false
    });
  }

  // 分页、改页、排序
  onPageChange = (sortString, currentPage, pageSize) => {
    this.searchValues.currentPage = currentPage;
    this.searchValues.pageSize = pageSize;
    this.searchValues.sortString = sortString;
    this.getNoticeHistoryData();
  }

  handleInputSearch = (value) => {
    this.searchValues.searchValueLike = value;
    this.searchValues.currentPage = 1;
    this.getNoticeHistoryData();
  }

  handlePressEnter = (event) => {
    this.searchValues.searchValueLike = event.target.value;
    this.searchValues.currentPage = 1;
    this.getNoticeHistoryData();
  }

  onSelectedRows = (selectedRows) => {
    this.setState({
      selectedRows: selectedRows || []
    });
  }

  handleCancel = () => {
    this.setState({
      modalVisible: false
    });
  };

  // ----------- 有效公告相关处理 start ------------
  
  // 获取有效公告信息
  getNoticeActiveData = () => {
    this.sendPostRequest('/system/notice/getNoticeActiveList', null, (result) => {
      if (result != null && result.length > 0) {
        this.setState({
          noticeActiveList: result || [],
          idList: result.map(item => item.id)
        });
        this.props.onCheck && this.props.onCheck('notice',2);
      }else{
        this.props.onCheck && this.props.onCheck('notice',1);
      }
    });
  }
  
  showNoticeActiveModal = () => {
    this.setState({
      noticeActiveModalVisible: true
    });
  }

  handleNoticeActiveModal = () => {
    const { idList, activeStatus } = this.state;
    super.sendPostRequest('/system/notice/updateNoticeActive', {idList,activeStatus}, () => {
      this.setState({
        noticeActiveModalVisible: false
      });
    });
    // 检查结束
    this.props.onCheckFinish && this.props.onCheckFinish();
  }

  onCheckboxChange = (event) => {
    this.setState({
      activeStatus: event.target.checked ? 0 : 1
    });
  }
  // ----------- 有效公告相关处理 end --------------

  render() {
    const { total, isLoading, modalVisible, dataSource, noticeActiveList, noticeActiveModalVisible } = this.state;

    return (
      <>
       {/* 有效公告弹出框 */}
       <Modal
       title="公告信息"
       visible={noticeActiveModalVisible}
       onCancel={this.handleNoticeActiveModal}
       className='addModal'
       bodyStyle={{ backgroundColor: '#f0f0f0', paddingTop: 0 }}
       width={800}
       footer={[
         <Checkbox key="reshowFlag" onChange={this.onCheckboxChange}>下次不再显示</Checkbox>,
         <Button key="accept" type="primary" style={{ marginLeft: 20 }} onClick={() => this.handleNoticeActiveModal(1)}>知道了</Button>
       ]}
     >
       {
         noticeActiveList.map(notice => {
           return (
             <div key={notice.id}>
               <div style={{ width: '100%', fontSize: 'medium', fontWeight: 'bold', marginBottom: 5, marginTop: 20 }}>【{notice.noticeName}】</div>
               <TextArea key="structure" autoSize={{ minRows: 4 }} readOnly value={notice.noticeContent} style={{ resize: 'none' }} />
             </div>
           );
         })
       }
     </Modal>
      {/* 历史公告弹框 */}
      <Modal
        title="查看历史公告"
        visible={modalVisible}
        onCancel={this.handleCancel}
        bodyStyle={{ maxHeight: 520 }}
        className="addModal paddingZeroModal"
        width={1000}
        footer={null}
      >
        <Search placeholder='请输入检索内容。' onSearch={this.handleInputSearch} onPressEnter={this.handlePressEnter} enterButton />
        <YWTSimpleTable
          tableKey={'tb_stockupAddModal'}
          columns={this.columns}
          dataSource={dataSource}
          pagination={true}
          total={total}
          onPageChange={this.onPageChange}
          current={this.searchValues.currentPage}
          defaultPageSize={this.searchValues.pageSize}
          rowSelectionType='radio'
          expandable={{
            // eslint-disable-next-line react/display-name
            expandedRowRender: record => <p style={{ margin: 10 }}>{`公告内容：${record.noticeContent}`}</p>
          }}
          height={'400px'}
          isLoading={isLoading}
          onSelectedRows={this.onSelectedRows}
        />
      </Modal>
      </>
    );
  }
}

export default enhance(NoticeModal);
