/* eslint-disable react/no-deprecated */
import React, { Component } from 'react';
import { Modal, Table, Switch } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { isEqual } from 'lodash';
import { PropTypes } from 'prop-types';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import './YWTColumnSettingModal.less';

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'pointer', color: '#000', width: 60 }} />);

const SortableItem = sortableElement(props => <tr {...props} style={{ zIndex: '1000' }} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

/**
 * 表格列设置页
 */
export default class YWTColumnSettingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnSettingModalVisible: false,
      columnData: [],
      hideColumnSet: new Set()
    };

    this.settingColumns = [
      {
        title: '排序',
        dataIndex: 'sort',
        width: 70,
        className: 'drag-visible',
        // eslint-disable-next-line react/display-name
        render: () => <DragHandle />
      },
      {
        title: '列名',
        dataIndex: 'title',
        className: 'drag-visible',
        ellipsis: true
      },
      {
        title: '操作',
        dataIndex: 'hideFlag',
        width: 100,
        className: 'drag-invisible',
        // eslint-disable-next-line react/display-name
        render: (text, record) => (
          <Switch defaultChecked={!this.state.hideColumnSet.has(record.key)} checkedChildren="显示" unCheckedChildren="隐藏" onChange={e => this.showOrHide(record, e)} />
        )
      }
    ];
  }

  showOrHide = (record, showFlag) => {
    const { hideColumnSet } = this.state;

    if (showFlag) {
      hideColumnSet.delete(record.key);
    } else {
      hideColumnSet.add(record.key);
    }

    this.setState({
      hideColumnSet
    });
  };

  initColumnsFromLocalStorage = () => {
    const { tableKey, orginColums } = this.props;

    // 从localStorage获取显示列KEY
    this.columnsInLocalStorage = JSON.parse(localStorage.getItem(tableKey + '_columnSetting')) || [];

    // localStorage无数据时、使用原始列
    if (this.columnsInLocalStorage.length === 0) {
      this.columns = orginColums;
      this.props.onInit(this.columns);
      return;
    }

    // localStorage有数据时、通过存储key值恢复显示列信息
    const recoverColumn = [];
    for (const colkey of this.columnsInLocalStorage) {
      const colDef = orginColums.find(item => isEqual(item.key, colkey));
      recoverColumn.push(colDef);
    }

    // 恢复显示列回调方法、反应到页面
    this.columns = recoverColumn;
    this.props.onInit(this.columns);
  };

  componentDidMount() {
    this.props.onRef(this);
    this.initColumnsFromLocalStorage();
  }

  componentWillUnmount() {
    this.props.onRef(null);
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { columnData } = this.state;
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(columnData), oldIndex, newIndex).filter(el => !!el);
      this.setState({ columnData: newData });
    }
  };

  DraggableContainer = props => <SortableContainer useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={this.onSortEnd} {...props} />;

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { columnData } = this.state;
    const index = columnData.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  editColumnSetting = () => {
    this.initColumnsFromLocalStorage();
    const { orginColums } = this.props;
    const columnData = [];
    const hideColumnSet = new Set();

    // 计算显示列和所有列的差集
    const diffColumns = orginColums.filter(col1 => !this.columns.some(col2 => isEqual(col2.key, col1.key)));
    // 所有列增加index
    orginColums.forEach((col, idx) => {
      col.index = idx;
    });

    // 列设置数据添加显示列
    this.columns.forEach(col => {
      if (!isEqual(col.key, 'action') && !isEqual(col.title, '　')) {
        columnData.push(col);
      }
    });

    // 列设置数据添加隐藏列、设置隐藏Set
    diffColumns.forEach(col => {
      if (!isEqual(col.key, 'action') && !isEqual(col.title, '　')) {
        hideColumnSet.add(col.key);
        columnData.push(col);
      }
    });

    this.setState({
      columnData,
      hideColumnSet,
      columnSettingModalVisible: true
    });
  };

  hideColumnSettingModal = () => {
    this.setState({
      columnData: [],
      columnSettingModalVisible: false
    });
  };

  reloadColumnsSetting = () => {
    const { tableKey } = this.props;
    const { columnData, hideColumnSet } = this.state;
    const hideColumnList = [...hideColumnSet];

    // 获取操作列
    const actionCol = this.columns.find(item => isEqual(item.key, 'action'));

    this.columns = columnData.filter(col => !hideColumnList.includes(col.key));

    // 操作列不为空时
    if (actionCol != null) {
      if (actionCol.index === 0) {
        // 操作列在第一列，添加到列表第一列
        this.columns.unshift(actionCol);
      } else {
        // 其他情况，添加到列表最后列
        this.columns.push(actionCol);
      }
    }

    const keyList = [];
    this.columns.forEach(item => {
      if (item.key) {
        keyList.push(item.key);
      }
    });

    // 把显示列存入localStorage
    localStorage.setItem(tableKey + '_columnSetting', JSON.stringify(keyList));

    // 回调调用出方法、列设置信息反应到页面
    this.props.onReload(this.columns);
    this.hideColumnSettingModal();
  };

  render() {
    const { columnSettingModalVisible, columnData } = this.state;

    return (
      <Modal
        title="列名显示设置"
        visible={columnSettingModalVisible}
        className="addModal paddingZeroModal"
        onCancel={this.hideColumnSettingModal}
        onOk={this.reloadColumnsSetting}
        destroyOnClose
      >
        <Table
          tableKey={'tb_columnSettingModal'}
          dataSource={columnData}
          columns={this.settingColumns}
          pagination={false}
          bordered
          size={'small'}
          rowKey="index"
          components={{
            body: {
              wrapper: this.DraggableContainer,
              row: this.DraggableBodyRow
            }
          }}
        />
      </Modal>
    );
  }
}

YWTColumnSettingModal.propTypes = {
  orginColums: PropTypes.array.isRequired,
  tableKey: PropTypes.string.isRequired,
  onRef: PropTypes.func.isRequired,
  onInit: PropTypes.func,
  onReload: PropTypes.func
};
