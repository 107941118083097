
// 禁止的权限列表Key
const DISABLE_PRIVILEGE_PATH_KEY = 'disablePrivilegePaths';

export default class PrivilegeService {
  // 设置禁止的权限列表
  static set disabledPrivilegeInfo(disablePrivilegePaths) {
    sessionStorage.setItem(DISABLE_PRIVILEGE_PATH_KEY, JSON.stringify(disablePrivilegePaths || []));
  }

  // 判断是否存在禁止权限
  static isDisabled(path) {
    const paths = JSON.parse(sessionStorage.getItem(DISABLE_PRIVILEGE_PATH_KEY)) || [];
    return (paths.includes(path));
  }
}
