export const addTabPane = (tabPane) => ({
  type: 'ADD_TAB_PANE',
  tabPane
});

export const changeTabPane = (activeKey) => ({
  type: 'CHANGE_TAB_PANE',
  activeKey: activeKey
});

export const deleteTabPane = (targetKey) => ({
  type: 'DELETE_TAB_PANE',
  targetKey: targetKey
});

export const logout = () => ({
  type: 'LOG_OUT'
});
