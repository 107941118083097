import {Input, Modal, Form} from 'antd';
import BaseComponent from 'base/BaseComponent';
import { enhance } from 'base/BaseEnhance';
import React from 'react';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

/**
 * 切换代理用户弹框
 */
class ChangePasswordModal extends BaseComponent {
  passwordChangeFormRef = React.createRef();
  oldPassword = null;
  newPassword = null;
  confirmPassword = null;
  // 密码设置规则
  sysPasswordRule = null;

  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  show = () => {
    this.getSysPasswordRule();
    this.setState({
      visible: true
    });
  }

  hide = () => {
    this.setState({
      visible: false
    });
  }

  // 获取密码设置规则
  getSysPasswordRule = () => {
    this.sendPostRequest('/system/common/getProfileByCode', { code: 'SYS_PASSWORD_RULE' }, (result) => {
      this.sysPasswordRule = result || null;
    });
  }

  confirmChangePassword = (values) => {
      if (values.confirmPassword !== values.newPassword) {
        super.showMessage('新密码与确认密码不一致，请重新输入');
        return;
      }
      super.sendPostRequest('/system/password/modifyPassword', values, () => {
        super.showMessage('密码修改成功，请重新登录', 'success');
        // 父节点存在对确认事件的处理的话，调用
        this.props && this.props.onOk && this.props.onOk();
      });
  }

  render() {
    const {visible } = this.state;

    return (
      <Modal width="300px" title="修改密码" visible={visible}
      onOk={()=> {this.passwordChangeFormRef.current.submit();}} onCancel={this.hide}
      okText="确认" cancelText="取消" className='addModal'>
      <Form {...layout} ref={this.passwordChangeFormRef} onFinish={this.confirmChangePassword}>
        <Form.Item
          label="旧密码"
          name="oldPassword"
          rules={[{ required: true, message: '请输入旧密码' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="新密码"
          name="newPassword"
          rules={(this.sysPasswordRule == null || this.sysPasswordRule === '')
            ? [{ required: true, message: '请输入新密码' }]
            : [{ required: true, message: '请输入新密码' }, { pattern: new RegExp(this.sysPasswordRule), message: '请输入符合密码设置规则的密码' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="确认密码"
          name="confirmPassword"
          rules={[{ required: true, message: '请输入确认密码' }]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
    );
  }
}

export default enhance(ChangePasswordModal);
