// Tab 相关处理
const tabs = (state = { activeKey: null, tabPanes: [] }, action) => {
  const { tabPanes } = state;
  switch (action.type) {
    // 新建 TAB
    case 'ADD_TAB_PANE':
      // 防止重复添加
      var tabPane = action.tabPane;
      if (tabPanes.find(tabInfo => tabInfo.key === tabPane.key)) {
        return Object.assign({}, state, {
          activeKey: tabPane.key
        });
      }

      tabPanes.push(tabPane);
      return Object.assign({}, state, {
        tabPanes: tabPanes.slice(),
        activeKey: tabPane.key
      });
    // 切换 TAB
    case 'CHANGE_TAB_PANE':
      return Object.assign({}, state, {
        activeKey: action.activeKey
      });
    // 删除 TAB
    case 'DELETE_TAB_PANE':
      var key = null;
      // 首页不会关闭，故滤过
      for (let i = 1; i < tabPanes.length; i++) {
        if (tabPanes[i].key === action.targetKey) {
          // 删除的是当前激活的Tab，默认激活前一个TAB
          if (state.activeKey === action.targetKey) {
            key = tabPanes[i - 1].key;
          } else {
            key = state.activeKey;
          }
          tabPanes.splice(i, 1);
          break;
        } else {
          key = state.activeKey;
        }
      }

      return Object.assign({}, state, {
        activeKey: key,
        tabPanes: tabPanes.slice()
      });
    default:
      return state;
  }
};

export default tabs;
