import loadable from '@loadable/component';

// 懒加载组件
const SysHospitalManagement = loadable(() => import('./sysHospitalManagement/SysHospitalManagement'));
const SupplierQuery = loadable(() => import('./supplierQuery/SupplierQuery'));
const InvoiceQuery = loadable(() => import('./invoiceQuery/InvoiceQuery'));
const WarehousQuiry = loadable(() => import('./warehousQuiry/WarehousQuiry'));
const OutboundOrderQuery = loadable(() => import('./outboundOrderQuery/OutboundOrderQuery'));
const ConsumptionQuiry = loadable(() => import('./consumptionQuiry/ConsumptionQuiry'));
const OrderQuiry = loadable(() => import('./orderQuiry/OrderQuiry'));
const RequisitionQuiry = loadable(() => import('./requisitionQuiry/RequisitionQuiry'));
const SettlementQuiry = loadable(() => import('./settlementQuiry/SettlementQuiry'));

export const subRoutes = [
  // 医院管理
  {
    path: '/SysHospitalManagement',
    component: SysHospitalManagement
  },
  // 供应商查询
  {
    path: '/SupplierQuery',
    component: SupplierQuery
  },
  // 发票查询
  {
    path: '/InvoiceQuery',
    component: InvoiceQuery
  },
  // 入库单查询
  {
    path: '/WarehousQuiry',
    component: WarehousQuiry
  },
  // 出库单查询
  {
    path: '/OutboundOrderQuery',
    component: OutboundOrderQuery
  },
  // 消耗单查询
  {
    path: '/ConsumptionQuiry',
    component: ConsumptionQuiry
  },
  // 订单查询
  {
    path: '/OrderQuiry',
    component: OrderQuiry
  },
  // 请领单查询
  {
    path: '/RequisitionQuiry',
    component: RequisitionQuiry
  },
  // 结算单查询
  {
    path: '/SettlementQuiry',
    component: SettlementQuiry
  }
];
