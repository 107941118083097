import { LockOutlined, SafetyOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Layout, Row, Tabs } from 'antd';
import BaseComponent from 'base/BaseComponent';
import { enhance } from 'base/BaseEnhance';
import Constants from 'constants/Constants';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from 'redux/actions/';
import PrivilegeService from 'service/PrivilegeService';
import SessionService from 'service/SessionService';
import './Login.less';

const { Footer, Content } = Layout;
const { TabPane } = Tabs;

const captchaImagePath = Constants.BACK_HOST_URI_PREFIX + '/getCaptchaImage';

/**
 * 登录界面
 */
class Login extends BaseComponent {
  constructor(props) {
    super(props, false);

    this.state = {
      captchaImagePath: captchaImagePath,
      needCaptcha: this.getNeedCaptcha()
    };

    // 清空state数据、防止其他用户登录时保留之前用户state信息
    this.props.logout();
  }

  getNeedCaptcha = () => {
    return SessionService.loginErrorCnt >= Constants.MAX_LOGIN_ERROR_CNT_WITHOUT_CAPTCHA;
  }

  handleSubmit = values => {
    this.sendPostRequest('login', { ...values }, (result) => {
      this.onLoginSuccess(result);
    });
  }

  smsLogin = values => {
    this.sendPostRequest('/plat-delivery/login/smsLogin', { ...values }, (result) => {
      this.onLoginSuccess(result);
    });
  }

  onLoginSuccess = (result) => {
    // 登录成功后，将错误计数改为0
    SessionService.loginErrorCnt = 0;
    SessionService.userName = result.userName;
    SessionService.localUserName = result.userName;
    SessionService.proxyUserName = result.proxyUserName;
    SessionService.systemType = result.systemType;
    PrivilegeService.disabledPrivilegeInfo = result.disabledFrontPrivilegePathList;
    this.showNotification('欢迎' + result.userName, 'success');

    if (result.resetPassword != null) {
      this.props.history.push('/', { resetPassword: '1' });
    } else {
      this.props.history.push('/');
    }
  }

  onError(errorMessage, errorCode) {
    if (errorCode === Constants.ERROR_CODE_NEED_CAPTCHA) {
      SessionService.loginErrorCnt = Constants.MAX_LOGIN_ERROR_CNT_WITHOUT_CAPTCHA;
    } else if (errorCode !== '0') {
      SessionService.loginErrorCnt++;
    }
    this.setState({
      needCaptcha: this.getNeedCaptcha()
    });
    super.onError(errorMessage);
    this.changeCaptchaImage();
  }

  changeCaptchaImage = () => {
    this.setState(
      {
        captchaImagePath: captchaImagePath + '?cnt=' + Math.random()
      }
    );
  }

  render() {
    return (
      <Layout className="ywt-layout-wrapper">
        <Content className="ywt-content-wrapper">
          <div className="ywt-content-inner-wrapper">
            <div className="ywt-logo-container"></div>
            <Tabs defaultActiveKey="1" className="customize-tabs-bar">
              <TabPane tab="账号登录" key="1">
                <Form onFinish={this.handleSubmit} style={{ marginTop: '10px' }}>
                  <Form.Item name="loginName" rules={[{ required: true, message: '用户名不能为空' }]}>
                    <Input addonBefore={<UserOutlined />} placeholder="请输入用户名" />
                  </Form.Item>
                  <Form.Item name="password" rules={[{ required: true, message: '密码不能为空' }]} className="ywt-password-input">
                    <Input.Password addonBefore={<LockOutlined />} type="password" placeholder="请输入密码" />
                  </Form.Item>
                  {
                    this.state.needCaptcha ? (
                      <Input.Group>
                        <Row gutter={8} type="flex" justify="space-around">
                          <Col span={8}>
                            <Form.Item name="captcha" rules={[{ required: true, message: '请输入验证码' }]} style={{ height: 40 }} className="ywt-captche-input">
                              <Input prefix={<SafetyOutlined />} placeholder="验证码" />
                            </Form.Item>
                          </Col>
                          <Col span={10}>
                            <img alt='验证码' src={this.state.captchaImagePath} onClick={this.changeCaptchaImage} height="40" style={{ cursor: 'pointer', width: 137 }} />
                          </Col>
                          <Col span={6}>
                            <Button onClick={this.changeCaptchaImage} className="ywt-captche-button">看不清</Button>
                          </Col>
                        </Row>
                      </Input.Group>) : ''
                  }
                  <Form.Item>
                    <Button type="primary" htmlType="submit" block
                      className="login-form-button ywt-login-button">登录</Button>
                  </Form.Item>
                </Form>
              </TabPane>
            </Tabs>
          </div>
        </Content>
        <Footer className="ywt-footer-wrapper"></Footer>
      </Layout>
    );
  }
}

export default connect(null, { logout })(enhance(Login));
